import { Collapse, Checkbox } from "antd";
const { Panel } = Collapse;

function CustomAccordion({
	items,
	subItems,
	workGroupsData,
	checkedItems,
	handleCheckboxChange,
}) {
	return (
		<div className="py-4">
			<Collapse defaultActiveKey={["0"]} className="mt-4">
				{items.map((item, index) => {
					return (
						<Panel header={item} key={index}>
							{subItems.map((subItem, subIndex) => (
								<div className="text-left">
									<Checkbox
										key={subIndex}
										onChange={(e) =>
											handleCheckboxChange(`${item}-${subItem.title}`, e)
										}
										checked={checkedItems[`${item}-${subItem.title}`]}
									>
										{subItem.title}
									</Checkbox>
								</div>
							))}
						</Panel>
					);
				})}
			</Collapse>
		</div>
	);
}

export default CustomAccordion;
