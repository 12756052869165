import axios from "axios";
import { useState } from "react";
import logo from "../../assets/logo/logo.png";

export default function Login() {
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [showLoginError, setShowLoginError] = useState(false);
	const submit = (e) => {
		e.preventDefault();
		const user = {
			username: username,
			password: password,
		};
		console.log("user", user);
		axios
			.post(process.env.REACT_APP_BACKEND_BASE_URL + "/api/token/", user, {
				headers: { "Content-Type": "application/json" },
				withCredentials: true,
			})
			.then((response) => {
				const { data } = response;
				console.log("data", data);
				if (data) {
					localStorage.clear();
					localStorage.setItem("access_token", data.access);
					localStorage.setItem("refresh_token", data.refresh);
					localStorage.setItem("username", username);
					axios.defaults.headers.common[
						"Authorization"
					] = `Bearer ${data["access"]}`;
					window.location.href = "/";
				} else {
					setShowLoginError(true);
				}
			})
			.catch((error) => {
				setShowLoginError(true);
			});
	};
	return (
		<section className="bg-gray-50 dark:bg-gray-900">
			<div className="flex flex-col items-center justify-center px-6 py-8 mx-auto h-screen lg:py-0">
				<img src={logo} className="w-[350px] object-contain mx-auto mb-5" />
				<div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
					<div className="p-6 space-y-4 md:space-y-6 sm:p-8">
						<h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
							Sign in to your account
						</h1>
						<form className="space-y-4 md:space-y-6" action="#">
							<div>
								<label
									htmlFor="username"
									className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
								>
									Username
								</label>
								<input
									// type="email"
									name="username"
									id="username"
									className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
									placeholder="Your username"
									required=""
									onChange={(e) => setUsername(e.target.value)}
								/>
							</div>
							<div>
								<label
									htmlFor="password"
									className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
								>
									Password
								</label>
								<input
									type="password"
									name="password"
									id="password"
									placeholder="••••••••"
									className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
									required=""
									onChange={(e) => setPassword(e.target.value)}
								/>
							</div>

							<button
								type="submit"
								className="w-full text-white bg-emerald-500 py-4 rounded-xl"
								onClick={(e) => submit(e)}
							>
								Sign in
							</button>
							{showLoginError && (
								<p className="text-red-500">Incorrect username or password</p>
							)}
						</form>
					</div>
				</div>
			</div>
		</section>
	);
}
