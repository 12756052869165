import "./App.css";
import { Routes, Route } from "react-router-dom";
import Login from "./pages/Login";
import MainPage from "./pages/MainPage";
import { ConfigProvider } from "antd";

function App() {
	return (
		<div className="App">
			<ConfigProvider
				theme={{
					token: {
						colorPrimary: "#10b981",
					},
				}}
			>
				<Routes>
					<Route path="/login" element={<Login />} />
					<Route path="/" element={<MainPage />} />
				</Routes>
			</ConfigProvider>
		</div>
	);
}

export default App;
