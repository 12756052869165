import { Button, Modal, Form, Input, Checkbox, message } from "antd";
import { useState, useRef } from "react";
import { AuthAxiosRequest } from "../../interceptors/axios";
import timeZones from "./allTimeZones.json";
import { Select } from "antd";

export default function SettingsButton(props) {
	const formRef = useRef(null);
	const [loading, setLoading] = useState(false);
	const [open, setOpen] = useState(false);
	const [changePassword, setChangePassword] = useState(false);
	const [changePomeloPassword, setChangePomeloPassword] = useState(false);
	const [messageApi, contextHolder] = message.useMessage();

	const defaultTimeZone = props.pomeloCreds.user_timezone;
	const username = localStorage.getItem("username");
	const showModal = () => {
		setOpen(true);
		setChangePassword(false);
		setChangePomeloPassword(false);
	};
	const handleOk = () => {
		formRef.current.submit();
		setLoading(true);
	};
	const handleCancel = () => {
		setOpen(false);
		setChangePassword(false);
		setChangePomeloPassword(false);
	};
	const onFinish = (values) => {
		values.username = username;
		if (!changePassword) {
			delete values.password;
			delete values.confirmPassword;
		}
		if (!changePomeloPassword) {
			delete values.pomeloPassword;
			delete values.confirmPomeloPassword;
		}
		console.log(values);
		AuthAxiosRequest.put("settings/", values)
			.then((response) => {
				if (response.status === 200 || response.status === 201) {
					messageApi.open({
						type: "success",
						content: response.data.message,
					});
					setLoading(false);
					setOpen(false);
					if (changePassword) {
						localStorage.clear();
					}
					window.location.href = "/";
				}
			})
			.catch((error) => {
				setLoading(false);
				messageApi.open({
					type: "error",
					content: error,
				});
			});
	};
	const onFinishFailed = (errorInfo) => {
		console.log("Failed:", errorInfo);
		setLoading(false);
	};
	return (
		<div>
			{contextHolder}
			<button
				onClick={showModal}
				className="inline-flex items-center justify-center w-full px-4 py-2 mb-2 text-lg text-white bg-green-500 rounded-md hover:bg-green-400 sm:w-auto sm:mb-0"
				data-primary="green-400"
				data-rounded="rounded-2xl"
				data-primary-reset="{}"
			>
				settings
			</button>
			<Modal
				open={open}
				title="Settings"
				onOk={handleOk}
				onCancel={handleCancel}
				footer={[
					<Button key="back" onClick={handleCancel}>
						Return
					</Button>,
					<Button
						key="save"
						// href="https://google.com"
						type="primary"
						loading={loading}
						onClick={handleOk}
					>
						Save
					</Button>,
				]}
			>
				<Form
					ref={formRef}
					name="basic"
					labelCol={{
						span: 8,
					}}
					wrapperCol={{
						span: 16,
					}}
					style={{
						maxWidth: 600,
					}}
					initialValues={{
						remember: true,
						pomeloEmail: props.pomeloCreds.email,
						...(defaultTimeZone && { timeZone: defaultTimeZone }), // Conditionally set the default time zone
					}}
					onFinish={onFinish}
					onFinishFailed={onFinishFailed}
					autoComplete="off"
				>
					<Form.Item label="Username" name="username">
						<p>{username} </p>
					</Form.Item>
					<div className="w-full flex justify-center pb-5">
						<Checkbox
							onChange={(e) => setChangePassword(e.target.checked)}
							className="mx-auto"
							defaultChecked={false}
						>
							<p className="font-bold">Change password</p>
						</Checkbox>
					</div>

					<Form.Item
						name="password"
						label="New Password"
						rules={
							changePassword
								? [
										{
											required: true,
											message: "Please input your password!",
										},
								  ]
								: []
						}
						hasFeedback
					>
						<Input.Password disabled={!changePassword} />
					</Form.Item>

					<Form.Item
						name="confirmPassword"
						label="Confirm Password"
						dependencies={["password"]}
						hasFeedback
						rules={
							changePassword
								? [
										{
											required: true,
											message: "Please confirm your password!",
										},
										({ getFieldValue }) => ({
											validator(_, value) {
												if (!value || getFieldValue("password") === value) {
													return Promise.resolve();
												}
												return Promise.reject(
													new Error(
														"The new password that you entered do not match!"
													)
												);
											},
										}),
								  ]
								: []
						}
					>
						<Input.Password disabled={!changePassword} />
					</Form.Item>

					<p className="font-bold text-center w-full pb-5">
						Pomelo credentials
					</p>

					<Form.Item
						name="pomeloEmail"
						label="Pomelo E-mail"
						rules={[
							{
								type: "email",
								message: "The input is not valid E-mail!",
							},
							{
								required: true,
								message: "Please input your E-mail!",
							},
						]}
					>
						<Input />
					</Form.Item>

					<div className="w-full flex justify-center pb-5">
						<Checkbox
							onChange={(e) => setChangePomeloPassword(e.target.checked)}
							className="mx-auto"
							defaultChecked={false}
						>
							<p className="font-bold">Change Pomelo password</p>
						</Checkbox>
					</div>

					<Form.Item
						name="pomeloPassword"
						label="Pomelo Password"
						rules={
							changePomeloPassword
								? [
										{
											required: true,
											message: "Please input your Pomelo password!",
										},
								  ]
								: []
						}
						hasFeedback
					>
						<Input.Password disabled={!changePomeloPassword} />
					</Form.Item>

					<Form.Item
						name="confirmPomeloPassword"
						label="Confirm Password"
						dependencies={["pomeloPassword"]}
						hasFeedback
						rules={
							changePomeloPassword
								? [
										{
											required: true,
											message: "Please confirm your Pomelo password!",
										},
										({ getFieldValue }) => ({
											validator(_, value) {
												if (
													!value ||
													getFieldValue("pomeloPassword") === value
												) {
													return Promise.resolve();
												}
												return Promise.reject(
													new Error(
														"The Pomelo passwords that you entered do not match!"
													)
												);
											},
										}),
								  ]
								: []
						}
					>
						<Input.Password disabled={!changePomeloPassword} />
					</Form.Item>
					<Form.Item
						label="Time Zone"
						name="timeZone"
						rules={[
							{
								required: true,
								message: "Please select your time zone!",
							},
						]}
					>
						<Select
							showSearch
							placeholder="Select or type your time zone"
							optionFilterProp="children"
							filterOption={(input, option) =>
								option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
						>
							{timeZones.timezones.map((zone) => (
								<Select.Option key={zone} value={zone}>
									{zone}
								</Select.Option>
							))}
						</Select>
					</Form.Item>
				</Form>
			</Modal>
		</div>
	);
}
