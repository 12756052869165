import axios from "axios";
// import authRequest from "../../interceptors/axios";

export default function LogoutButton() {
	const logout = () => {
		localStorage.clear();
		axios.defaults.headers.common["Authorization"] = null;
		window.location.href = "/login";
		// (async () => {
		// 	try {
		// 		const { data } = await axios.post(
		// 			"http://localhost:8000/logout/",
		// 			{
		// 				refresh_token: localStorage.getItem("refresh_token"),
		// 			},
		// 			{ headers: { "Content-Type": "application/json" } },
		// 			{ withCredentials: true }
		// 		);
		// 		localStorage.clear();
		// 		axios.defaults.headers.common["Authorization"] = null;
		// 		window.location.href = "/login";
		// 	} catch (e) {
		// 		console.log("logout not working", e);
		// 	}
		// })();
	};

	return (
		<button
			onClick={() => logout()}
			className="inline-flex items-center justify-center w-full px-4 py-2 mb-2 text-lg text-white bg-green-500 rounded-md hover:bg-green-400 sm:w-auto sm:mb-0"
			data-primary="green-400"
			data-rounded="rounded-2xl"
			data-primary-reset="{}"
		>
			Log out
			<svg
				className="w-4 h-4 ml-1"
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 20 20"
				fill="currentColor"
			>
				<path
					fillRule="evenodd"
					d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
					clipRule="evenodd"
				></path>
			</svg>
		</button>
	);
}
