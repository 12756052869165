import React, { useEffect, useRef, useState } from "react";
import { List, Checkbox } from "antd";

export default function UserLogs(props) {
	const containerRef = useRef(null);
	const [autoScroll, setAutoScroll] = useState(true);

	const convertToLocalTime = (utcTimestamp) => {
		const localDate = new Date(utcTimestamp);
		return localDate.toLocaleString();
	};

	useEffect(() => {
		if (autoScroll && containerRef.current) {
			containerRef.current.scrollTop = containerRef.current.scrollHeight;
		}
	}, [props.logs, autoScroll]);

	return (
		<div>
			<div className="max-h-[400px] overflow-y-auto" ref={containerRef}>
				<List
					bordered
					dataSource={props.logs}
					renderItem={(log) => (
						<List.Item>
							<div>
								<span className="text-yellow-500">
									[{convertToLocalTime(log.timestamp)}]
								</span>{" "}
								<span className={log.type}>{log.content}</span>
							</div>
						</List.Item>
					)}
					className="w-full"
				/>
			</div>
			<div className="flex justify-start mt-2">
				<Checkbox
					checked={autoScroll}
					onChange={(e) => setAutoScroll(e.target.checked)}
				>
					Auto Scroll Down
				</Checkbox>
			</div>
		</div>
	);
}
