import React, { useEffect, useState } from "react";
import {
	Collapse,
	Button,
	Modal,
	Radio,
	TimePicker,
	Checkbox,
	Tooltip,
	message,
	DatePicker,
} from "antd";
// import dayjs from "dayjs";
import { AuthAxiosRequest } from "../../interceptors/axios";
import { ClockCircleOutlined, CalendarOutlined } from "@ant-design/icons";
// import utc from "dayjs-plugin-utc";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(timezone);

const { Panel } = Collapse;
const weekDays = [
	"Monday",
	"Tuesday",
	"Wednesday",
	"Thursday",
	"Friday",
	"Saturday",
	"Sunday",
];

function ClinicAccordion(props) {
	const [editTimeRange, setEditTimeRange] = useState({ from: null, to: null });
	const [modalVisible, setModalVisible] = useState(false);
	const [selectedType, setSelectedType] = useState(null);
	const [selectedDays, setSelectedDays] = useState([]);
	const [dateTimeRange, setDateTimeRange] = useState({ from: null, to: null });
	const [currentKey, setCurrentKey] = useState(null);
	const [editIndex, setEditIndex] = useState(null);
	const [apiError, setApiError] = useState(false);

	const [data, setData] = useState({
		"Clinic Days": [],
		"FHO Clinics": [],
		"STAT Days": [],
		Holidays: [],
	});

	const fromUTCDateTime = (utcDateTime) => {
		// return dayjs.utc(utcDateTime).local().format("YYYY-MM-DD HH:mm");
		return dayjs(utcDateTime)
			.tz(props.user_timezone)
			.format("YYYY-MM-DD HH:mm");
	};

	const fromUTC = (utcTime) => {
		return dayjs
			.utc(utcTime)
			.tz(props.user_timezone)
			.format("MMM D, YYYY, h:mm A");
	};

	const formatDate = (dateString) => {
		return fromUTC(dateString);
	};

	const fromUTCTime = (utcTime) => {
		const today = dayjs().format("YYYY-MM-DD");
		return dayjs
			.utc(`${today}T${utcTime}Z`)
			.tz(props.user_timezone)
			.format("HH:mm");
	};
	// const fromUTCTime = (utcTime) => {
	// 	const today = dayjs().format("YYYY-MM-DD");
	// 	return dayjs
	// 		.utc(`${today}T${utcTime}Z`)
	// 		.tz("America/Toronto")
	// 		.format("HH:mm");
	// };

	const toUTCTime = (localTime) => {
		const today = dayjs().format("YYYY-MM-DD");
		return dayjs(`${today}T${localTime}`).utc().format("HH:mm");
	};

	const handleAdd = (key) => {
		setCurrentKey(key);
		setEditIndex(null);
		setSelectedType(
			key === "STAT Days" || key === "Holidays"
				? "dateTimeInterval"
				: "weekDays"
		);
		setSelectedDays([...new Set(selectedDays)]);
		setModalVisible(true);
	};

	const handleEdit = (key, index) => {
		setCurrentKey(key);
		setEditIndex(index);
		const currentItem = data[key][index];
		if (currentItem.day) {
			setSelectedType("weekDays");
			setSelectedDays([currentItem.day]);
			setEditTimeRange({
				from: currentItem.from,
				to: currentItem.to,
			});
		} else {
			setSelectedType("dateTimeInterval");
			setEditTimeRange({ from: currentItem.fromDate, to: currentItem.toDate });
		}
		setModalVisible(true);
	};

	const handleTimeRangeChange = (timeRange, day = null) => {
		const [from, to] = timeRange;
		const fromValue = from.format("HH:mm");
		const toValue = to.format("HH:mm");

		if (editIndex !== null) {
			setEditTimeRange({ from: toUTCTime(fromValue), to: toUTCTime(toValue) });
		} else if (day) {
			setDateTimeRange((prev) => ({
				...prev,
				[day]: { from: fromValue, to: toValue },
			}));
		} else {
			setDateTimeRange({ from: fromValue, to: toValue });
		}
	};

	const handleSave = async () => {
		try {
			// Ensure timezone is included in the data being sent
			const dataWithTimezone = {
				...data,
				timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
			};
			console.log(dataWithTimezone);
			await AuthAxiosRequest.post("/timeframe/", dataWithTimezone);
			message.success("Data saved successfully!");
		} catch (error) {
			message.error("An error occurred while saving the data.");
		}
	};

	const handleClear = (key) => {
		setData((prevData) => ({ ...prevData, [key]: [] }));
		message.info(`${key} cleared.`);
	};

	useEffect(() => {
		if (editIndex !== null) {
			const currentItem = data[currentKey][editIndex];
			if (currentItem.day) {
				setEditTimeRange({ from: currentItem.from, to: currentItem.to });
			} else {
				setEditTimeRange({
					from: currentItem.fromDate,
					to: currentItem.toDate,
				});
			}
		}
	}, [editIndex]);

	useEffect(() => {
		const fetchTimeframes = async () => {
			try {
				const response = await AuthAxiosRequest.get("/timeframe/");
				const fetchedData = response.data;

				// Organize the fetched data into the desired structure
				const organizedData = {
					"Clinic Days": [],
					"FHO Clinics": [],
					"STAT Days": [],
					Holidays: [],
				};

				fetchedData.forEach((item) => {
					let mappedItem = {};
					mappedItem.id = item.id;
					mappedItem.from = item.from_time;
					mappedItem.to = item.to_time;
					mappedItem.fromDate = item.from_date;
					mappedItem.toDate = item.to_date;
					mappedItem.day = item.day;

					if (item.type in organizedData) {
						organizedData[item.type].push(mappedItem);
					}
				});

				setData(organizedData);
			} catch (error) {
				setApiError(true);
				message.error(
					"An error occurred while fetching the timeframes. " + error.response
						? error.response.data.message
						: error.message
				);
			}
		};

		fetchTimeframes();
	}, []);

	const handleModalOk = () => {
		if (
			selectedType === "weekDays" &&
			data[currentKey].some((item) => item.fromDate)
		) {
			message.error(
				"Please clear the Datetime interval before adding Week days with specific times."
			);
			return;
		}

		if (
			selectedType === "dateTimeInterval" &&
			data[currentKey].some((item) => item.day)
		) {
			message.error(
				"Please clear the Week days with specific times before adding a Datetime interval."
			);
			return;
		}
		if (editIndex !== null) {
			const updatedData = [...data[currentKey]];
			const currentItem = updatedData[editIndex];
			if (currentItem.day) {
				updatedData[editIndex] = {
					...currentItem,
					from: editTimeRange.from,
					to: editTimeRange.to,
				};
			} else {
				updatedData[editIndex] = {
					...currentItem,
					fromDate: editTimeRange.from,
					toDate: editTimeRange.to,
				};
			}
			setData((prevData) => ({ ...prevData, [currentKey]: updatedData }));
		} else {
			const newData =
				selectedType === "weekDays"
					? selectedDays
							.filter((day) => !data[currentKey].some((d) => d.day === day))
							.map((day) => {
								if (!dateTimeRange[day]) {
									message.error(`Please provide a time range for ${day}.`);
									return null;
								}
								return {
									day,
									from: toUTCTime(dateTimeRange[day].from),
									to: toUTCTime(dateTimeRange[day].to),
								};
							})
							.filter(Boolean) // This will remove any null values from the array
					: [{ fromDate: dateTimeRange.from, toDate: dateTimeRange.to }];

			const combinedData = [...data[currentKey], ...newData];
			setData((prevData) => ({
				...prevData,
				[currentKey]: combinedData,
			}));
		}
		setModalVisible(false);
	};

	const handleDateTimeRangeChange = (dates) => {
		const fromValue = dates[0];
		const toValue = dates[1];

		if (editIndex !== null) {
			setEditTimeRange({ from: fromValue, to: toValue });
		} else {
			setDateTimeRange({ from: fromValue, to: toValue });
		}
	};
	const hasDateTimeInterval = data[currentKey]?.some((item) => item.fromDate);
	const hasWeekDays = data[currentKey]?.some((item) => item.day);

	const handleDelete = () => {
		if (editIndex !== null && currentKey) {
			const updatedData = [...data[currentKey]];
			updatedData.splice(editIndex, 1); // Remove the item at editIndex
			setData((prevData) => ({ ...prevData, [currentKey]: updatedData }));
			setModalVisible(false);
			setEditIndex(null);
		}
	};

	return (
		!apiError && (
			<div className="container mx-auto py-4">
				<Collapse defaultActiveKey={["0"]} className="mt-4">
					{Object.keys(data).map((key, index) => (
						<Panel
							header={key !== "FHO Clinics" ? key : "After-Hours Clinics"}
							key={index}
						>
							{data[key].map((item, idx) => (
								<div
									key={idx}
									className="flex justify-between my-2 items-center"
								>
									{item.day ? (
										<div className="flex items-center">
											<ClockCircleOutlined className="mr-2" />
											<p className="text-blue-600 font-medium">
												<span className="success">{`${item.day} ${fromUTCTime(
													item.from
												)}`}</span>{" "}
												-{" "}
												<span className="error">{`${fromUTCTime(
													item.to
												)}`}</span>
											</p>
										</div>
									) : (
										<div className="flex items-center">
											<CalendarOutlined className="mr-2" />
											<p className="font-medium">
												<span className="success">{`${formatDate(
													item.fromDate
												)}`}</span>{" "}
												-{" "}
												<span className="error">{`${formatDate(
													item.toDate
												)}`}</span>
											</p>
										</div>
									)}
									<Button onClick={() => handleEdit(key, idx)}>Edit</Button>
								</div>
							))}
							<div className="flex justify-end gap-3 mt-4">
								<Button
									className="error"
									onClick={() => handleClear(key)}
									style={{ marginLeft: "10px" }}
								>
									Clear
								</Button>
								<Button onClick={() => handleAdd(key)} type="primary">
									Add
								</Button>
							</div>
						</Panel>
					))}
				</Collapse>
				<div className="w-full flex justify-end">
					<Button type="primary" onClick={handleSave} className="mt-4">
						Save
					</Button>
				</div>

				<Modal
					title={editIndex !== null ? "Edit Data" : "Add Data"}
					open={modalVisible}
					onCancel={() => {
						setModalVisible(false);
						setSelectedDays([]);
						setDateTimeRange({ from: null, to: null });
					}}
					onOk={handleModalOk}
					footer={[
						<Button
							onClick={handleDelete}
							hidden={editIndex === null}
							className="bg-red-500 text-white"
							type="danger"
						>
							Delete
						</Button>,
						<Button
							key="cancel"
							onClick={() => {
								setModalVisible(false);
								setSelectedDays([]);
								setDateTimeRange({ from: null, to: null });
							}}
						>
							Cancel
						</Button>,
						<Button key="submit" type="primary" onClick={handleModalOk}>
							OK
						</Button>,
					]}
				>
					{editIndex !== null ? (
						<div>
							{selectedType === "weekDays" ? (
								<>
									<Checkbox checked={true} disabled>
										{data[currentKey][editIndex].day}
									</Checkbox>
									<TimePicker.RangePicker
										format="HH:mm"
										value={[
											dayjs(fromUTCTime(editTimeRange.from), "HH:mm"),
											dayjs(fromUTCTime(editTimeRange.to), "HH:mm"),
										]}
										onChange={(timeRange) => handleTimeRangeChange(timeRange)}
										showNow
									/>
								</>
							) : (
								<>
									<DatePicker.RangePicker
										showTime={{
											format: "HH:mm",
											minuteStep: 1,
											secondStep: 60,
										}}
										format="YYYY-MM-DD HH:mm"
										value={[
											dayjs(
												fromUTCDateTime(editTimeRange.from),
												"YYYY-MM-DD HH:mm"
											),
											dayjs(
												fromUTCDateTime(editTimeRange.to),
												"YYYY-MM-DD HH:mm"
											),
										]}
										onChange={handleDateTimeRangeChange}
										order
										showNow
									/>
								</>
							)}
						</div>
					) : (
						<Radio.Group
							onChange={(e) => setSelectedType(e.target.value)}
							value={selectedType}
						>
							<Radio
								value="weekDays"
								disabled={
									hasDateTimeInterval ||
									currentKey === "STAT Days" ||
									currentKey === "Holidays" ||
									currentKey === "FHO Clinics"
								}
							>
								Week days with specific times
								<Tooltip
									title={
										hasDateTimeInterval
											? "Please clear the Datetime interval before adding Week days with specific times."
											: currentKey === "STAT Days" ||
											  currentKey === "Holidays" ||
											  currentKey === "FHO Clinics"
											? "Week days with specific times is disabled for STAT Days, Holidays, and After-Hours Clinics."
											: "Add specific times for each selected day."
									}
								>
									<span style={{ marginLeft: 5 }}>ℹ️</span>
								</Tooltip>
							</Radio>
							{selectedType === "weekDays" &&
								!hasDateTimeInterval &&
								currentKey !== "STAT Days" &&
								currentKey !== "FHO Clinics" &&
								currentKey !== "Holidays" && (
									<div>
										{weekDays
											.filter(
												(day) => !data[currentKey].some((d) => d.day === day)
											)
											.map((day) => (
												<div key={day}>
													<Checkbox
														onChange={() => {
															if (selectedDays.includes(day)) {
																setSelectedDays((prev) =>
																	prev.filter((d) => d !== day)
																);
															} else {
																setSelectedDays((prev) => [...prev, day]);
															}
														}}
													>
														{day}
													</Checkbox>
													<TimePicker.RangePicker
														format="HH:mm"
														value={
															dateTimeRange[day]
																? [
																		dayjs(dateTimeRange[day].from, "HH:mm"),
																		dayjs(dateTimeRange[day].to, "HH:mm"),
																  ]
																: null
														}
														onChange={(timeRange) =>
															handleTimeRangeChange(timeRange, day)
														}
														showNow
													/>
												</div>
											))}
									</div>
								)}
							<Radio
								value="dateTimeInterval"
								disabled={hasWeekDays || currentKey === "Clinic Days"}
							>
								Datetime interval
								<Tooltip
									title={
										hasDateTimeInterval
											? "Please clear the Week days with specific times before adding a Datetime interval."
											: currentKey === "Clinic Days"
											? "Datetime Interval is disabled for Clinic Days."
											: "Add a continuous date-time interval."
									}
								>
									<span style={{ marginLeft: 5 }}>ℹ️</span>
								</Tooltip>
							</Radio>
							{selectedType === "dateTimeInterval" && (
								<DatePicker.RangePicker
									showNow
									showTime={{ format: "HH:mm", minuteStep: 1, secondStep: 60 }}
									format="YYYY-MM-DD HH:mm"
									value={
										editIndex !== null
											? editTimeRange.from && editTimeRange.to
												? [
														dayjs(editTimeRange.from, "YYYY-MM-DD HH:mm"),
														dayjs(editTimeRange.to, "YYYY-MM-DD HH:mm"),
												  ]
												: null
											: dateTimeRange.from && dateTimeRange.to
											? [
													dayjs(dateTimeRange.from, "YYYY-MM-DD HH:mm"),
													dayjs(dateTimeRange.to, "YYYY-MM-DD HH:mm"),
											  ]
											: null
									}
									onChange={handleDateTimeRangeChange}
									order
								/>
							)}
						</Radio.Group>
					)}
				</Modal>
			</div>
		)
	);
}

export default ClinicAccordion;
