import LogoutButton from "../../components/LogoutButton";
import SettingsButton from "../../components/SettingsButton";
import WorkGroupsAccordion from "../../components/WorkGroupsAccordion";
import TimeframeAccordion from "../../components/TimeframeAccordion";
import UserLogs from "../../components/UserLogs";
import { useState, useEffect } from "react";
import { AuthAxiosRequest } from "../../interceptors/axios";
import { message } from "antd";
import { Spin } from "antd";
import logo from "../../assets/logo/logo.png";

export default function MainPage() {
	const [loadingPage, setLoadingPage] = useState(true);
	const [isAuth, setIsAuth] = useState(false);
	const [pomeloCreds, setPomeloCreds] = useState(null);
	const [logs, setLogs] = useState([]);
	const [workGroups, setWorkGroups] = useState(null);
	const [messageApi, contextHolder] = message.useMessage();
	const [loadingUpdateWorkGroups, setLoadingUpdateWorkGroups] = useState(false);

	useEffect(() => {
		if (localStorage.getItem("access_token") !== null) {
			setLoadingPage(false);
			setIsAuth(true);
		} else {
			window.location.href = "/login";
		}
	}, [isAuth]);

	useEffect(() => {
		// Define the fetch function
		const fetchLogs = () => {
			AuthAxiosRequest.get("user/logs/").then((response) => {
				setLogs(response.data);
			});
		};

		// Call the fetch function immediately when the component mounts
		fetchLogs();

		// Set up the interval to call the fetch function every 3 seconds
		const intervalId = setInterval(fetchLogs, 3000);

		// Clear the interval when the component is unmounted
		return () => clearInterval(intervalId);
	}, []); // Empty dependency array means this useEffect runs once when component mounts and cleans up when it unmounts

	useEffect(() => {
		AuthAxiosRequest.get("settings/").then((response) => {
			setPomeloCreds(response.data);
		});
		AuthAxiosRequest.get("user/work_groups/").then((response) => {
			setWorkGroups(response.data);
		});
	}, []);

	const updateWorkGroups = () => {
		setLoadingUpdateWorkGroups(true);
		messageApi.open({
			type: "loading",
			content: "Please wait and don't refresh until page reloads",
			duration: 30,
		});
		AuthAxiosRequest.put("user/update_work_groups/").then((response) => {
			if (response.data.error) {
				messageApi.open({
					type: "error",
					content: response.data.error,
					duration: 30,
				});
				setLoadingUpdateWorkGroups(false);
			} else {
				setWorkGroups(response.data);
				messageApi.open({
					type: "success",
					content: "Successfully updated work groups",
					duration: 8,
				});
				setLoadingUpdateWorkGroups(false);
				window.location.href = "/";
			}
		});
	};

	return (
		<>
			{loadingPage ? (
				<div className="w-screen h-screen flex justify-center items-center">
					<Spin />
				</div>
			) : (
				<div id="main-page">
					{contextHolder}
					<div className="header p-5 shadow-lg">
						<div className="md:hidden">
							<img
								src={logo}
								className="w-[220px] object-contain mx-auto mb-5"
							/>
						</div>
						<div className="header flex w-100 justify-between align-center">
							<div>
								{pomeloCreds && <SettingsButton pomeloCreds={pomeloCreds} />}
							</div>
							<img
								src={logo}
								className="hidden md:block w-[350px] object-contain mx-auto"
							/>
							<div>
								<LogoutButton />
							</div>
						</div>
					</div>
					<div id="main-page" className="container mx-auto p-4">
						<div className="my-5">
							<h1 className="text-2xl font-bold">Workgroups and Timeframes</h1>
							<div className="lg:flex lg:gap-5 w-full">
								{workGroups && (
									<div className="lg:w-1/2 w-full">
										<WorkGroupsAccordion
											workGroups={workGroups}
											updateWorkGroups={() => updateWorkGroups()}
											messageApi={messageApi}
											loadingUpdateWorkGroups={loadingUpdateWorkGroups}
										/>
									</div>
								)}
								{pomeloCreds && (
									<div className="lg:w-1/2 w-full">
										<TimeframeAccordion
											user_timezone={pomeloCreds.user_timezone}
										/>
									</div>
								)}
							</div>
						</div>

						<div className="mb-5">
							<h1 className="text-2xl font-bold mb-5">Logs</h1>
							<UserLogs logs={logs} />
						</div>
					</div>
				</div>
			)}
		</>
	);
}
