import React, { useState } from "react";
import CustomAccordion from "./CustomAccordion";
import { Button } from "antd";
import { AuthAxiosRequest } from "../../interceptors/axios";

function WorkGroupsAccordion(props) {
	let { messageApi } = props;
	const [checkedItems, setCheckedItems] = useState(() => {
		let initialState = {};
		props.workGroups.work_groups.forEach((group) => {
			group.patient_groups.forEach((patientId) => {
				const patient = props.workGroups.patient_groups.find(
					(p) => p.id === patientId
				);
				if (patient) {
					initialState[`${group.title}-${patient.title}`] = true;
				}
			});
		});
		return initialState;
	});
	const handleCheckboxChange = (key, e) => {
		setCheckedItems((prevState) => ({
			...prevState,
			[key]: e.target.checked,
		}));
	};
	const handleSave = () => {
		const selectedData = props.workGroups.work_groups.map((group) => {
			return {
				title: group.title,
				patient_groups: props.workGroups.patient_groups
					.filter((patient) => checkedItems[`${group.title}-${patient.title}`])
					.map((patient) => patient.id),
			};
		});

		AuthAxiosRequest.post("user/work_groups/change/", selectedData)
			.then((response) => {
				messageApi.open({
					type: "success",
					content: response.data.message,
				});
			})
			.catch((error) => {
				messageApi.open({
					type: "error",
					content: error,
				});
			});
	};
	return (
		<div>
			<CustomAccordion
				items={props.workGroups.work_groups.map((group) => group.title)}
				subItems={props.workGroups.patient_groups}
				workGroupsData={props.workGroups.work_groups}
				checkedItems={checkedItems}
				handleCheckboxChange={handleCheckboxChange}
			/>
			<div className="flex gap-2 justify-end">
				<Button
					type="default"
					onClick={() => props.updateWorkGroups()}
					loading={props.loadingUpdateWorkGroups}
				>
					Get Work Groups
				</Button>
				<Button type="primary" onClick={handleSave}>
					Save
				</Button>
			</div>
		</div>
	);
}

export default WorkGroupsAccordion;
